import { FC } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { RiskFilterValue } from '../../../../models/TableViewFilters';
import Badge from '../../../shared/badge/Badge';
import { useTranslation } from 'react-i18next';
import { RiskRatingKeys } from '../../../../models/Risk';

const StatusFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const riskFilters = filter as RiskFilterValue | undefined;
  const { t } = useTranslation(['risk']);

  return (
    <div className="flex flex-wrap items-center gap-2">
      {riskFilters?.map((riskRating) => (
        <div key={riskRating}>
          <Badge textClass="text-dpm-gray-2 !font-normal" text={`${t(RiskRatingKeys[riskRating])}`} />
        </div>
      ))}
    </div>
  );
};

export default StatusFilterOverview;
